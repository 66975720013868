import styled, { createGlobalStyle } from "styled-components";
import BGimage from './images/purple_background.jpg'

export const GlobalStyle = createGlobalStyle `
    html {
        height: 100%
    }

    body {
        background-image: url(${BGimage});
        backgorund-size: cover;
        margin: 0;
        padding: 0 20px;
        display: flex;
        justify-content: center;
    }

    * {
        box-sizing: border-box;
        font-family: 'Roboto Condensed';
    }
`

export const Wrapper = styled.div`

display: flex;
flex-direction: column;
align-items: center;

> p {
    color: #fff;
}

.score {
    color: #fff;
    font-size: 2rem;
    margin: 0;
}

h1 {
    font-family: 'Roboto', sans-serif;
    background-image: linear-gradient(180deg, #fff, #87f1ff)
    background-size: 100%;
    background-clip: text;
}


`